import React, { Component } from "react";
import {
  Table,
  Tag,
  Button,
  Drawer,
  Form,
  Input,
  Select,
  InputNumber,
  DatePicker,
  message,
  Popconfirm,
  Collapse,
} from "antd";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";
import { createNewSpd, getSpdDetails, UpdateSpd } from "../../api/index";
import moment from "moment";
import AssetDelete from "../site_components/asset_delete";
import { checkServiceType } from "../../actions/service-type";

class Spd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      showModal: false,
      open: false,
      openEdit: false,
      openViewMoreDetails: false,
      selectedElement: "",
      title: "",
      tabaleLoading: false,
      actionView: false,
      selectedAssetId: null,
      AssetType: [],
      selectedMakeId: null,
      makeType: [],
      spdDeployedLocation: "",
      spdSerialNumber: "",
      spdMaterialCode: "",
      spdWarrantyExpireDate: null,
      spdInstalledDate: null,
      SPDClass: "",
      hrcFuseIsolatorStatus: "",
      AssetManagementPermission: false,

      spdDropdownObject: {
        spdDeployedLocation: "",
        spdProtectionViaHrcFuseIsolator: "",
      },
    };
    this.requestFromClose = this.requestFromClose.bind(this);
    this.handleDeployedLocation = this.handleDeployedLocation.bind(this);
    this.handleSerialNumber = this.handleSerialNumber.bind(this);
    this.handleMaterialCode = this.handleMaterialCode.bind(this);
    this.handleSPDClass = this.handleSPDClass.bind(this);
    this.handleHrcFuseIsolatorStatus =
      this.handleHrcFuseIsolatorStatus.bind(this);
  }
  spdUpdata(id) {
    console.log("dfdfd");
    console.log(id);
    this.getDataSpdDetails(id);
    this.setState({ openEdit: true });
  }
  getDataSpdDetails = async (id) => {
    var res = await getSpdDetails(id);

    if (res.success) {
      this.setState({
        spdEdit: res.data,
        spdId: res.data.id,
        spdSerialNumber: res.data.serialNumber,
        selectedMakeId: res.data.make.id,
        makeType: res.data.make,
        spdDeployedLocation: res.data.deployedLocation,
        hrcFuseIsolatorStatus: res.data.hrcFuseIsolatorStatus,
      });
      if (res.data?.warrantyExpireDate != null) {
        this.setState({
          spdWarrantyExpireDate: res.data?.warrantyExpireDate,
        });
      } else {
        this.setState({
          spdWarrantyExpireDate: "",
        });
      }
      if (res.data?.installedDate != null) {
        this.setState({
          spdInstalledDate: res.data?.installedDate,
        });
      } else {
        this.setState({
          spdInstalledDate: "",
        });
      }
    }
  };
  handleHrcFuseIsolatorStatus(e) {
    this.setState({ hrcFuseIsolatorStatus: e });
  }
  handleSPDClass(e) {
    //console.log(e.target.value)
    this.setState({ SPDClass: e.target.value });
  }
  handleMaterialCode(e) {
    //console.log(e.target.value)
    this.setState({ spdMaterialCode: e.target.value });
  }
  handleSerialNumber(e) {
    //console.log(e.target.value)
    this.setState({ spdSerialNumber: e.target.value });
  }
  handleDeployedLocation(e) {
    //console.log(e.target.value)
    this.setState({ spdDeployedLocation: e });
  }
  requestFromClose() {
    console.log("dsdsd");
    this.setState({ showModal: true, open: true });
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("serviceTypeSet")) == null) {
      const { history } = this.props;

      history.push("/");
    } else {
      this.getSetPageData(
        JSON.parse(localStorage.getItem("serviceTypeSet")),
        38
      );
    }
    checkServiceType(38).then((data) =>
      this.setState(
        {
          AssetManagementPermission: data,
        },
        () => {}
      )
    );
  }
  getSetPageData = (serviceTypeIs, Pageid) => {
    if (
      serviceTypeIs.serviceType.filter(
        (checkuser) => checkuser?.id == Pageid
      )[0] != null
    ) {
      console.log("fddf");
      this.setState({
        actionView: true,
      });
    }
  };
  onDrawerClose = () => {
    console.log("sddsds");
    this.formRef.current.resetFields();
    this.validator.hideMessages();
    this.setState({
      open: false,
      openEdit: false,
      selectedAssetId: null,

      AssetType: [],
      selectedMakeId: null,
      makeType: [],
      spdDeployedLocation: "",
      spdSerialNumber: "",
      spdMaterialCode: "",
      spdWarrantyExpireDate: null,
      spdInstalledDate: null,
      SPDClass: "",
      hrcFuseIsolatorStatus: "",
    });
  };
  onChangeAsset = (value) => {
    console.log("onChangeAsset");
    //console.log(value);
    this.setState({
      selectedAssetId: value,
      AssetType: {
        id: value,
      },
    });
  };
  onChangeMake = (value) => {
    console.log("onChangeMake");
    //console.log(value);
    this.setState({
      selectedMakeId: value,
      makeType: {
        id: value,
      },
    });
  };
  onChangeWarrantyExpireDate = (date, dateString) => {
    console.log(date);
    console.log(dateString);
    this.setState({
      spdWarrantyExpireDate: dateString,
    });
  };
  onChangeInstalledDate = (date, dateString) => {
    console.log(date);
    console.log(dateString);
    this.setState({
      spdInstalledDate: dateString,
    });
  };
  AllDataSubmitUpdate = async () => {
    if (this.validator.allValid()) {
      var dataSet;

      dataSet = {
        id: this.state.spdId,

        site: { id: this.props?.detailsSite?.site_details.id },
        asset: {
          id: 2,
        },
        make: this.state.makeType,

        deployedLocation: this.state.spdDeployedLocation,
        serialNumber: this.state.spdSerialNumber,
        materialCode: this.state.spdMaterialCode,
        warrantyExpireDate: this.state.spdWarrantyExpireDate,
        installedDate: this.state.spdInstalledDate,
        hrcFuseIsolatorStatus: this.state.hrcFuseIsolatorStatus,
      };
      console.log(dataSet);
      var res = await UpdateSpd(dataSet);
      if (res.success) {
        message.success("Successfully SPD Edited!");
        this.formRef.current.resetFields();

        this.setState({
          showModal: false,
          open: false,
          openEdit: false,

          selectedAssetId: null,
          AssetType: [],
          selectedMakeId: null,
          makeType: [],
          spdDeployedLocation: "",
          spdSerialNumber: "",
          spdMaterialCode: "",
          spdWarrantyExpireDate: null,
          spdInstalledDate: null,
          SPDClass: "",
          hrcFuseIsolatorStatus: "",
        });
        this.props.parentMethod();
        // const { history } = this.props;
        // history.push("/site");
      } else {
        message.warning(" Something went wrong!");
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };
  AllDataSubmit = async () => {
    if (this.validator.allValid()) {
      var dataSet;
      dataSet = {
        site: { id: this.props?.detailsSite?.site_details.id },
        asset: {
          id: 2,
        },
        make: this.state.makeType,

        deployedLocation: this.state.spdDeployedLocation,
        serialNumber: this.state.spdSerialNumber,
        materialCode: this.state.spdMaterialCode,
        warrantyExpireDate: this.state.spdWarrantyExpireDate,
        installedDate: this.state.spdInstalledDate,
        SPDClass: this.state.SPDClass,
        hrcFuseIsolatorStatus: this.state.hrcFuseIsolatorStatus,
      };
      console.log(dataSet);
      var res = await createNewSpd(dataSet);
      if (res.success) {
        message.success("Successfully SPD Added!");
        this.formRef.current.resetFields();

        this.setState({
          showModal: false,
          open: false,
          openEdit: false,

          selectedAssetId: null,
          AssetType: [],
          selectedMakeId: null,
          makeType: [],
          spdDeployedLocation: "",
          spdSerialNumber: "",
          spdMaterialCode: "",
          spdWarrantyExpireDate: null,
          spdInstalledDate: null,
          SPDClass: "",
          hrcFuseIsolatorStatus: "",
        });
        this.props.parentMethod();
        // const { history } = this.props;
        // history.push("/site");
      } else {
        message.warning(" Something went wrong!");
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };
  getDataDetailsForDelete = async (id) => {
    var res = await getSpdDetails(id);
    console.log(res);
    if (res.success) {
      var respons = AssetDelete.Action(
        res.data,
        this.props?.detailsSite?.site_details.id,
        UpdateSpd
      );

      respons.then((value) => {
        if (value == 1) {
          message.success("Successfully Delete!");
          this.props.parentMethod();
        } else {
          message.warning(" Something went wrong!");
        }
      });
    }
  };
  submitForm = async (item) => {
    this.getDataDetailsForDelete(item);
  };

  viewMoreDetails(element) {
    this.setState({
      showModal2: true,
      openViewMoreDetails: true,
      selectedElement: element,
    });
  }

  onViewMoreDetailsDrawerClose = () => {
    this.setState({
      showModal2: false,
      openViewMoreDetails: false,
    });
  };

  validatedTextLable = (field) => {
    return field !== null ? (
      <span class="badge badge-outline-secondary text-muted">{field}</span>
    ) : (
      <span class="badge text-danger">Currently Not Set</span>
    );
  };

  render() {
    const { Option } = Select;
    const { Panel } = Collapse;
    const _spd = this.state.selectedElement;
    const columns2 = [
      {
        title: "Deployed Location",
        content: this.validatedTextLable(_spd.deployedLocation),
        details: [],
      },
      {
        title: "Make",
        content:
          _spd !== "" ? (
            <Tag color="gray" style={{ margin: "0px" }}>
              <span>{_spd.make?.name}</span>
            </Tag>
          ) : (
            <></>
          ),
        details: [
          {
            title: "Material Code Of The SPD",
            content: this.validatedTextLable(_spd.make?.materialCode),
          },
          {
            title: "SPD Class",
            content: this.validatedTextLable(_spd.make?.spdclass),
          },
        ],
      },
      {
        title: "Serial Number",
        content: this.validatedTextLable(_spd.serialNumber),
        details: [],
      },
      {
        title: "Warranty Expire Date ",
        content: this.validatedTextLable(_spd.warrantyExpireDate),
        details: [],
      },
      {
        title: "SPD Protection via HRC Fuse/Isolator",
        content: this.validatedTextLable(_spd.hrcFuseIsolatorStatus),
        details: [],
      },
      {
        title: "Installed Date",
        content: this.validatedTextLable(_spd.installedDate),
        details: [],
      },
    ];

    return (
      <>
        <div className="row">
          <div class="col-lg-12">
            <div class="text-end">
              <ul class="list-inline">
                <li class="list-inline-item">
                  {this.state.actionView ? (
                    <button
                      type="button"
                      class="btn btn-soft-primary btn-sm"
                      onClick={this.requestFromClose}
                    >
                      <i class="fas fa-plus me-2"></i>Add SPDs
                    </button>
                  ) : (
                    ""
                  )}
                </li>
              </ul>
            </div>
          </div>

          {this.props.details.access_technologies_data != null ? (
            <div className="col-lg-2" style={{ paddingBottom: 12 }}>
              <table class="table table-dark2 mb-0" style={{ fontSize: 12 }}>
                <tbody>
                  <tr>
                    <td scope="row">
                      <b>No. of SPDs:</b>
                    </td>
                    <td>
                      {this.props.details2.spd_data.length}
                    </td>
                    <td scope="row"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}
          <div className="row">
            {this.props.details2.spd_data.map((element) => (
              <div className="col-md-4">
                <div class="card">
                  <div class="card-header">
                    <div class="d-flex justify-content-start align-items-center">
                      <img
                        src={
                          "https://static.vecteezy.com/system/resources/previews/001/902/502/large_2x/telecommunication-radio-antenna-and-satelite-tower-with-blue-sky-free-photo.JPG"
                        }
                        alt=""
                        class="thumb-md rounded"
                      />
                      <div
                        class="badge badge-soft-primary"
                        style={{
                          padding: "5px",
                          marginLeft: "15px",
                          border: "1px solid rgba(0, 0, 0, 0.05)",
                        }}
                      >
                        <i
                          class="la la-home font-14 text-dark"
                          style={{ paddingLeft: "10px" }}
                        />
                        <i
                          class="la la-angle-right text-dark font-14"
                          style={{ padding: "5px" }}
                        />
                        <span class="fw-semibold text-pink">
                          {this.props?.detailsSite?.site_details?.siteName?.substring(
                            0,
                            11
                          )}
                        </span>
                        <i
                          class="la la-angle-right text-dark font-14"
                          style={{ padding: "5px" }}
                        />
                        <span
                          class="fw-semibold text-primary"
                          style={{ paddingRight: "10px" }}
                        >
                          {element.make?.name?.substring(0, 17)}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="col">
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <h6 class="fw-normal m-0">Make</h6>
                        <span class="badge bg-secondary">
                          {element.make?.name}
                        </span>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <h6 class="fw-normal m-0">Serial Number</h6>
                        <span class="text-muted fw-normal">
                          {element.serialNumber}
                        </span>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <h6 class="fw-normal m-0">Deployed Location</h6>
                        <span class="badge badge-soft-success fw-semibold ms-2">
                          <i class="far fa-fw dripicons-location"></i>{" "}
                          {element.deployedLocation}{" "}
                        </span>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <h6 class="fw-normal m-0">Warranty Expire Date</h6>
                        <span class="badge badge-soft-pink fw-semibold">
                          <i class="far fa-fw mdi mdi-calendar-range"></i>{" "}
                          {element.warrantyExpireDate}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <p class="card-footer m-0">
                    <div class="d-flex justify-content-between align-items-center">
                      <a
                        href="#custom-modal"
                        class="btn btn-soft-dark btn-sm waves-effect btn-round"
                        onClick={() => this.viewMoreDetails(element)}
                      >
                        <i class="mdi mdi mdi-unfold-more-horizontal"></i> More
                        Details{" "}
                      </a>
                      <div class="text-end">
                        {this.state.AssetManagementPermission ? (
                          <button
                            type="button"
                            class="btn btn-soft-primary btn-icon-square-sm"
                            onClick={() => this.spdUpdata(element.id)}
                            style={{ marginRight: "8px" }}
                          >
                            <i class="mdi mdi-pencil-outline"></i>
                          </button>
                        ) : (
                          ""
                        )}
                        <Popconfirm
                          title="Are You Sure?"
                          description="Are you sure to delete this task?"
                          onConfirm={() => this.submitForm(element.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          {this.state.AssetManagementPermission ? (
                            <button
                              type="button"
                              class="btn btn-soft-danger btn-icon-square-sm"
                            >
                              <i class="mdi mdi-trash-can-outline"></i>
                            </button>
                          ) : (
                            ""
                          )}
                        </Popconfirm>
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            ))}
          </div>

          <Drawer
            maskClosable={false}
            title={"SPD Details"}
            width={"50%"}
            placement="right"
            onClose={() => this.onViewMoreDetailsDrawerClose()}
            visible={this.state.openViewMoreDetails}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            <Collapse
              style={{ borderColor: "lavender", alignContent: "center" }}
            >
              {columns2.map((item) => (
                <Panel
                  style={{ borderColor: "lavender" }}
                  header={
                    <li class="d-flex justify-content-between align-items-center">
                      <div>
                        {item.details.length == 0 ? (
                          <i
                            class="la la-hand-o-right text-primary"
                            style={{ paddingLeft: "5px", paddingRight: "5px" }}
                          />
                        ) : (
                          <></>
                        )}
                        <span class="fw-normal m-2">{item.title}</span>
                      </div>
                      {item.content}
                    </li>
                  }
                  key={item.title}
                  showArrow={item.details.length > 0}
                  collapsible={item.details.length > 0 ? "" : "icon"}
                >
                  {item.details.map((detail) => (
                    <li
                      class="d-flex justify-content-between align-items-center me-2"
                      style={{ paddingBottom: "10px", paddingLeft: "10px" }}
                    >
                      <div>
                        <i class="la la-circle text-primary me-2"></i>{" "}
                        <span>{detail.title}</span>
                      </div>
                      {detail.content}
                    </li>
                  ))}
                </Panel>
              ))}
            </Collapse>
          </Drawer>

          <Drawer
            maskClosable={false}
            title={<span className="textStyles-small" style={{ fontSize: "14px" }}>New SPD</span>}
            width={"50%"}
            placement="right"
            onClose={() => this.onDrawerClose()}
            visible={this.state.open}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="card" style={{ borderColor: "white" }}>
                  <div className="card-body">
                    <Form ref={this.formRef}>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className='textStyles-small'>
                              Deployed Location <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="deployedLocation"
                            tooltip="This is a required field"
                          >
                            <div>
                              <Select
                                style={{ width: "100%" }}
                                className='borderedSelect'
                                bordered={false}
                                id="deployedLocation"
                                onChange={(e) => {
                                  this.handleDeployedLocation(e);
                                }}
                              >
                                {this.props.dropdown["spdDropdownObject"][
                                  "spdDeployedLocation"
                                ]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p className="textStyles-small" style={{ color: "#f5325c", marginTop: 5 }}>
                              {this.validator.message(
                                "Deployed Location",
                                this.state.spdDeployedLocation,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className='textStyles-small'>
                              Make <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="Is_active"
                            tooltip="This is a required field"
                          >
                            <Select
                              showSearch
                              onChange={this.onChangeMake}
                              style={{ width: "100%" }}
                              className='borderedSelect'
                              bordered={false}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.props.spdMasterData?.spdMasterData?.spdMakeList?.map(
                                (item, index) => {
                                  return (
                                    <Option value={item?.id}>
                                      {item?.name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                            <p className="textStyles-small" style={{ color: "#f5325c", marginTop: 5 }}>
                              {this.validator.message(
                                "Make",
                                this.state.selectedMakeId,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className='textStyles-small'>
                              SPD Serial Number <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="serialNumber"
                            tooltip="This is a required field"
                          >
                            <Input
                              id="serialNumber"
                              onChange={this.handleSerialNumber}
                              className='borderedSelect'
                              style={{ height: 34 }}
                            />
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "Serial Number",
                                this.state.spdSerialNumber,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className='textStyles-small'>
                              Warranty Expire Date
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="warrantyExpireDate"
                            tooltip="This is a required field"
                          >
                            <DatePicker
                              style={{ width: "100%", height: 34 }}
                              onChange={this.onChangeWarrantyExpireDate}
                              className='borderedSelect'
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className='textStyles-small'>
                              SPD Protection via HRC Fuse/Isolator{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="hrcFuseIsolatorStatus"
                            tooltip="This is a required field"
                          >
                            <div>
                              <Select
                                style={{ width: "100%" }}
                                id="hrcFuseIsolatorStatus"
                                className='borderedSelect'
                                bordered={false}
                                onChange={(e) => {
                                  this.handleHrcFuseIsolatorStatus(e);
                                }}
                              >
                                {this.props.dropdown["spdDropdownObject"][
                                  "spdProtectionViaHrcFuseIsolator"
                                ]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p className="textStyles-small" style={{ color: "#f5325c", marginTop: 5 }}>
                              {this.validator.message(
                                "SPD Protection via HRC Fuse/Isolator",
                                this.state.hrcFuseIsolatorStatus,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className='textStyles-small'>
                              Installed Date
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="installedDate"
                            tooltip="This is a required field"
                          >
                            <DatePicker
                              className='borderedSelect'
                              style={{ width: "100%", height: 34 }}
                              onChange={this.onChangeInstalledDate}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Form>
                    <div className="row" style={{ justifyContent: "end" }}>
                      <div className="col-md-2">
                        <Button
                          className="btn btn-primary"
                          style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', marginRight: '5px' }}
                          onClick={() => this.AllDataSubmit()}
                        >
                          <span className="textStyles-small" style={{ color: 'white' }}>Add This Asset</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>

          <Drawer
            maskClosable={false}
            title={<span className="textStyles-small" style={{ fontSize: "14px" }}>Edit SPD</span>}
            width={"50%"}
            placement="right"
            onClose={() => this.onDrawerClose()}
            visible={this.state.openEdit}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="card" style={{ borderColor: "white" }}>
                  <div className="card-body">
                    <Form ref={this.formRef}>
                      <div className="row">

                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              Deployed Location <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <div>
                              <Select
                                style={{ width: "100%" }}
                                className="borderedSelect"
                                bordered={false}
                                id="deployedLocation"
                                value={this.state.spdDeployedLocation}
                                onChange={(e) => {
                                  this.handleDeployedLocation(e);
                                }}
                              >
                                {this.props.dropdown["spdDropdownObject"][
                                  "spdDeployedLocation"
                                ]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Deployed Location",
                                this.state.spdDeployedLocation,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        {this.state.selectedMakeId != null ? (
                          <div className="col-md-6">
                            <label className="form-label" for="depot">
                              <span className="textStyles-small">
                                Make <x style={{ color: "red" }}>*</x>
                              </span>
                            </label>
                            <Form.Item
                              required
                              name="Is_active"
                              tooltip="This is a required field"
                            >
                              <Select
                                showSearch
                                className="borderedSelect"
                                bordered={false}
                                onChange={this.onChangeMake}
                                defaultValue={this.state.selectedMakeId}
                                style={{ width: "100%" }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                {this.props.spdMasterData?.spdMasterData?.spdMakeList?.map(
                                  (item, index) => {
                                    return (
                                      <Option value={item?.id}>
                                        {item?.name}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                              <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                                {this.validator.message(
                                  "Make",
                                  this.state.selectedMakeId,
                                  "required"
                                )}
                              </p>
                            </Form.Item>
                          </div>
                        ) : (
                          <></>
                        )}

                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              SPD Serial Number <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <Input
                              id="serialNumber"
                              value={this.state.spdSerialNumber}
                              onChange={this.handleSerialNumber}
                              className="borderedSelect"
                              style={{ height: 34 }}
                            />
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "Serial Number",
                                this.state.spdSerialNumber,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        {this.state.spdWarrantyExpireDate != null ? (
                          <div className="col-md-6">
                            <label className="form-label" for="depot">
                              <span className="textStyles-small">
                                Warranty Expire Date
                              </span>
                            </label>
                            <Form.Item>
                              <DatePicker
                                defaultValue={
                                  this.state.spdWarrantyExpireDate != ""
                                    ? moment(this.state.spdWarrantyExpireDate)
                                    : ""
                                }
                                className="borderedSelect"
                                style={{ width: "100%", height: 34 }}
                                onChange={this.onChangeWarrantyExpireDate}
                              />
                            </Form.Item>
                          </div>
                        ) : (
                          <></>
                        )}

                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            <span className="textStyles-small">
                              SPD Protection via HRC Fuse/Isolator{" "}
                              <x style={{ color: "red" }}>*</x>
                            </span>
                          </label>
                          <Form.Item
                            required
                            name="hrcFuseIsolatorStatus"
                            tooltip="This is a required field"
                          >
                            <div>
                              <Select
                                className="borderedSelect"
                                bordered={false}
                                style={{ width: "100%" }}
                                id="hrcFuseIsolatorStatus"
                                value={this.state.hrcFuseIsolatorStatus}
                                onChange={(e) => {
                                  this.handleHrcFuseIsolatorStatus(e);
                                }}
                              >
                                {this.props.dropdown["spdDropdownObject"][
                                  "spdProtectionViaHrcFuseIsolator"
                                ]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c", marginTop: 5 }} className="textStyles-small">
                              {this.validator.message(
                                "SPD Protection via HRC Fuse/Isolator",
                                this.state.hrcFuseIsolatorStatus,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        {this.state.spdInstalledDate != null ? (
                          <div className="col-md-6">
                            <label className="form-label" for="depot">
                              <span className="textStyles-small">
                                Installed Date
                              </span>
                            </label>
                            <Form.Item>
                              <DatePicker
                                className="borderedSelect"
                                defaultValue={
                                  this.state.spdInstalledDate != ""
                                    ? moment(this.state.spdInstalledDate)
                                    : ""
                                }
                                style={{ width: "100%", height: 34 }}
                                onChange={this.onChangeInstalledDate}
                              />
                            </Form.Item>
                          </div>
                        ) : (
                          <></>
                        )}

                      </div>
                    </Form>
                    <div className="row" style={{ justifyContent: "end" }}>
                      <div className="col-md-2">
                        <Button
                          className="btn btn-primary"
                          onClick={() => this.AllDataSubmitUpdate()}
                          style={{ borderColor: "#BA183A", backgroundColor: "#BA183A", borderRadius: "5px", marginRight: "5px" }}
                        >
                          <span className="textStyles-small" style={{ color: "white" }}>Edit This Asset</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
        </div>
      </>
    );
  }
}
export default Spd;
