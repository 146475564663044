import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { DatePicker, Table, InputNumber, Input, Avatar, Tag, message, Collapse, Descriptions, Row, Col } from 'antd';
import moment from 'moment';

import { runEquations, dataUpdateGeneralDetails } from "../../api/index";
import { set } from "js-cookie";

const { Panel } = Collapse;



class GeneralDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: "",
			sec1Item1: '',
			sec1Item2: true,
			sec1Item3: 'show',
			sec2Item1: 'collapsed',
			sec2Item2: false,
			sec2Item3: '',
			switchStatus: true,
			objectSet: {
				"puNoOfwipProjects": 0,
				"sdPowerDependencies": null,
				"sdSAQDependencies": null,
				"sdCivilDependencies": null,
				"lastMonthCommercialPowerConsumption": 0.0,
				"lastMonthSolarGeneration": 0.0,
				"lastMonthGeneratorRunningHours": 0.0,
				"lastMonthPGRunningHours": 0.0,
				"lastMonthCommercialPowerCost": 0.0,
				"lastMonthFuelFillingLiters": 0.0,
				"lastMonthFuelCost": 0.0,
				"lastMonthPGCost": 0.0,
			}
		};
	}

	componentDidMount() {
		console.log("sssssssssss")
		console.log(this.props.details)
		this.setState({
			objectSet: {
				"puNoOfwipProjects": this.props.details1?.access_technologies_data?.puNoOfwipProjects,
				"sdPowerDependencies": this.props.details1?.access_technologies_data?.sdPowerDependencies,
				"sdSAQDependencies": this.props.details1?.access_technologies_data?.sdSAQDependencies,
				"sdCivilDependencies": this.props.details1?.access_technologies_data?.sdCivilDependencies,
				"lastMonthCommercialPowerConsumption": this.props.details1?.access_technologies_data?.lastMonthCommercialPowerConsumption,
				"lastMonthSolarGeneration": this.props.details1?.access_technologies_data?.lastMonthSolarGeneration,
				"lastMonthGeneratorRunningHours": this.props.details1?.access_technologies_data?.lastMonthGeneratorRunningHours,
				"lastMonthPGRunningHours": this.props.details1?.access_technologies_data?.lastMonthPGRunningHours,
				"lastMonthCommercialPowerCost": this.props.details1?.access_technologies_data?.lastMonthCommercialPowerCost,
				"lastMonthFuelFillingLiters": this.props.details1?.access_technologies_data?.lastMonthFuelFillingLiters,
				"lastMonthFuelCost": this.props.details1?.access_technologies_data?.lastMonthFuelCost,
				"lastMonthPGCost": this.props.details1?.access_technologies_data?.lastMonthPGCost,




			}
		})
	}

	runEquations = async () => {
		var form = new FormData();
		form.append("siteId", this.props.details.site_details.id);
		var res = await runEquations(form);
		if (res.success) {
			message.success('Successful..!')
			this.props.parentMethod();
		} else {
			message.warning(' Something went wrong!')
		}
	}
	SiteInfoSection(element) {
		if (element == 1) {
			this.setState({
				sec1Item1: this.state.sec1Item1 != '' ? '' : 'collapsed',
				sec1Item2: this.state.sec1Item2 ? false : true,
				sec1Item3: this.state.sec1Item3 != '' ? '' : 'show',
			}, () => {

				if (this.state.sec2Item2 == true) {
					this.setState({
						sec2Item1: this.state.sec2Item1 != '' ? '' : 'collapsed',
						sec2Item2: this.state.sec2Item2 ? false : true,
						sec2Item3: this.state.sec2Item3 != '' ? '' : 'show',
					})
				}
			})

		}

		if (element == 2) {
			this.setState({
				sec2Item1: this.state.sec2Item1 != '' ? '' : 'collapsed',
				sec2Item2: this.state.sec2Item2 ? false : true,
				sec2Item3: this.state.sec2Item3 != '' ? '' : 'show',
			}, () => {
				if (this.state.sec1Item2 == true) {
					this.setState({
						sec1Item1: this.state.sec1Item1 != '' ? '' : 'collapsed',
						sec1Item2: this.state.sec1Item2 ? false : true,
						sec1Item3: this.state.sec1Item3 != '' ? '' : 'show',
					})

				}
			})
		}



	}

	handlsdCivilDependencies = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				sdCivilDependencies: e.target.value
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handlsdSAQDependencies = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				sdSAQDependencies: e.target.value
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handlsdPowerDependencies = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				sdPowerDependencies: e.target.value
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handllastMonthPGCost = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				lastMonthPGCost: e
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handllastMonthFuelCost = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				lastMonthFuelCost: e
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handllastMonthFuelFillingLiters = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				lastMonthFuelFillingLiters: e
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handllastMonthCommercialPowerCost = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				lastMonthCommercialPowerCost: e
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handllastMonthPGRunningHours = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				lastMonthPGRunningHours: e
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handllastMonthGeneratorRunningHours = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				lastMonthGeneratorRunningHours: e
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handllastMonthSolarGeneration = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				lastMonthSolarGeneration: e
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handllastMonthCommercialPowerConsumption = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				lastMonthCommercialPowerConsumption: e
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handlpuNoOfwipProjects = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				puNoOfwipProjects: e
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}

	dataUpdate() {
		console.log("ssss")
		this.setState({
			switchStatus: false
		})
	}
	close = async () => {
		this.props.parentMethod();
		this.setState({
			objectSet: {
				"puNoOfwipProjects": this.props.details1?.access_technologies_data?.puNoOfwipProjects,
				"sdPowerDependencies": this.props.details1?.access_technologies_data?.sdPowerDependencies,
				"sdSAQDependencies": this.props.details1?.access_technologies_data?.sdSAQDependencies,
				"sdCivilDependencies": this.props.details1?.access_technologies_data?.sdCivilDependencies,
				"lastMonthCommercialPowerConsumption": this.props.details1?.access_technologies_data?.lastMonthCommercialPowerConsumption,
				"lastMonthSolarGeneration": this.props.details1?.access_technologies_data?.lastMonthSolarGeneration,
				"lastMonthGeneratorRunningHours": this.props.details1?.access_technologies_data?.lastMonthGeneratorRunningHours,
				"lastMonthPGRunningHours": this.props.details1?.access_technologies_data?.lastMonthPGRunningHours,
				"lastMonthCommercialPowerCost": this.props.details1?.access_technologies_data?.lastMonthCommercialPowerCost,
				"lastMonthFuelFillingLiters": this.props.details1?.access_technologies_data?.lastMonthFuelFillingLiters,
				"lastMonthFuelCost": this.props.details1?.access_technologies_data?.lastMonthFuelCost,
				"lastMonthPGCost": this.props.details1?.access_technologies_data?.lastMonthPGCost,




			}
		})
		this.setState({
			switchStatus: true
		});
	}
	dataSetToUpdate = async () => {

		console.log(this.state.objectSet)
		console.log(this.props.detailsupdateGeneralDetails.updateGeneralDetails)
		let remoteJob = {
			...this.props.detailsupdateGeneralDetails.updateGeneralDetails,
			...this.state.objectSet
		};
		const formData = new FormData();
		formData.append('id', this.props.details1?.access_technologies_data?.id);
		formData.append('object', JSON.stringify(remoteJob));

		var res = await dataUpdateGeneralDetails(formData);

		if (res.success) {
			message.success('Successfully Update!')
			this.props.parentMethod();
			this.setState({
				switchStatus: true
			})
		} else {
			message.warning(' Something went wrong!')
		}


	}

	render() {
		const columns123 = [
			{
				title: "Name",
				key: "Name",
				render: (item) => <span> <img style={{ width: 23 }} src={'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'} />  {item?.name}</span>,
			},
			{
				title: "Mobile Number",
				//   dataIndex: "accessPermissionCode",
				key: "Number",
				render: (item) => <span>{item?.mobileNumber}</span>,
			},
			{
				title: "Email",
				//   dataIndex: "accessPermissionCode",
				key: "Email",
				render: (item) => <span>{item?.email}</span>,
			},
		];
		const { site_details } = this.props.details

		console.log('site_powerData')
		console.log(this.props)

		const siteGenaralDetails = [
			{
				key: "1",
				title: "Site Information Section #1",
				children: 
				<Fragment>
														<div class="col-lg-12">
										<ul class="list-group">
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-home text-muted font-16 me-2"></i>Operator
												</div>
												<span class="badge badge-outline-primary badge-pill">{site_details?.depot?.region?.organization?.name}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-map-pin text-muted font-18 me-2"></i>District
												</div>

												<span class="badge badge-outline-secondary badge-pill">{site_details?.district?.nameEn}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-key text-muted font-16 me-2"></i>Site Owner
												</div>
												<span class="badge badge-outline-success badge-pill">{site_details?.siteOwner?.name}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-box text-muted font-16 me-2"></i>Site Category
												</div>
												<span class="badge badge-outline-warning">{site_details?.siteCategory?.name}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-align-center text-muted font-16 me-2"></i>Site Type
												</div>
												<span class="badge badge-outline-info badge-pill">{site_details?.siteType?.name}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-home text-muted font-16 me-2"></i>Site Elevation (m)
												</div>
												<span class="badge badge-outline-info badge-pill">{site_details?.siteElevation}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-battery text-muted font-18 me-2"></i>Energy Category
												</div>

												<span class="badge badge-outline-secondary badge-pill">{site_details?.energyCategory?.name}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-award text-muted font-16 me-2"></i>Entity
												</div>
												<span class="badge badge-outline-success badge-pill">{site_details?.entity?.name}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-rss text-muted font-16 me-2"></i>Indoor/Outdoor
												</div>
												<span class="badge badge-outline-info badge-pill">{site_details?.indoorOutdoor == null ? ('N/A') : (site_details?.indoorOutdoor?.name)}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-money text-muted font-18 me-2"></i>Shelter Type
												</div>

												<span class="badge badge-outline-warning">{site_details?.shelterType?.name}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-clock text-muted font-16 me-2"></i>Accessible Hours
												</div>
												<span class="badge badge-outline-success badge-pill">{site_details?.weekendsOrHolidaysAccessibleHours}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-money text-muted font-18 me-2"></i>Outside Ambient Tempture (Cel)
												</div>
												<span class="badge badge-outline-success badge-pill">{site_details?.ambientTemperature}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-map-marker text-muted font-18 me-2"></i>Site Key Location
												</div>
												<span class="badge badge-outline-success badge-pill">{site_details?.keyLocation ? site_details?.keyLocation : 'N/A'}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-user text-muted font-18 me-2"></i>Site Owner Name
												</div>
												<span class="badge badge-outline-success badge-pill">{site_details?.siteOwnerNameForAccess ? site_details?.siteOwnerNameForAccess : 'N/A'}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-street-view text-muted font-18 me-2"></i>Site Owner Address
												</div>
												<span class="badge badge-outline-success badge-pill">{site_details?.siteOwnerAddressForAccess ? site_details?.siteOwnerAddressForAccess : 'N/A'}</span>
											</li>
										</ul>
									</div>
				</Fragment>
			}
		]

		const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

		const items = [
			{
			  key: '1',
			  label: 'This is panel header 1',
			  children: <p>{text}</p>,
			},
			{
			  key: '2',
			  label: 'This is panel header 2',
			  children: <p>{text}</p>,
			},
			{
			  key: '3',
			  label: 'This is panel header 3',
			  children: <p>{text}</p>,
			},
		  ];

		return (
			<div style={{ marginTop: 20, backgroundColor: '#FAFAFA' }}>
				<div class="col-lg-12">
					<div class="text-end">
						<ul class="list-inline">
							<li class="list-inline-item">
								<button type="button" class="btn btn-soft-primary btn-sm" onClick={this.runEquations}><i class="fas dripicons-toggles me-2"></i><span class="textStyles-small">Run Equations</span></button>
							</li>
						</ul>
					</div>
				</div>

				<div style={{ backgroundColor: 'white', padding: 10, marginTop: 10, borderRadius: 10 }}>
					<ul className="steppedprogress pt-1">
						<li className="complete"><span>Planing</span></li>
						<li className="complete"><span>SAQ</span></li>
						<li className="complete"><span>Civil Work</span></li>
						<li className="complete finish"><span>Live</span></li>
					</ul>
				</div>

				<div style={{backgroundColor: 'white', padding: 10, marginTop: 10, borderRadius: 10}}>
					<Collapse defaultActiveKey={['1']} ghost={true}>
						<Panel header={<span className='textStyles-small' style={{fontSize: 13, fontWeight: 'bold'}} >Site Information Section 01</span>}
							key="1">
							<Fragment>
								<Row>
									<Col span={12}>
										<Descriptions bordered size="small" column={1} style={{ borderRadius: 15, width: '99%' }}>
											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Operator</span>}>
												<Tag color="pink" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.depot?.region?.organization?.name}</span>
												</Tag>
											</Descriptions.Item>
											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>District</span>}>
												<Tag color="orange" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.district?.nameEn}</span>
												</Tag>
											</Descriptions.Item>
											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Site Owner</span>}>
												<Tag color="green" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.siteOwner?.name}</span>
												</Tag>
											</Descriptions.Item>
											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Site Category</span>}>
												<Tag color="blue" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.siteCategory?.name ? site_details?.siteCategory?.name : 'N/A'}</span>
												</Tag>
											</Descriptions.Item>
											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Site Type</span>}>
												<Tag color="gray" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.siteType?.name}</span>
												</Tag>
											</Descriptions.Item>
											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Site Elevation (m)</span>}>
												<Tag color="red" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.siteElevation ? site_details?.siteElevation : 'N/A'}</span>
												</Tag>
											</Descriptions.Item>
											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Energy Category</span>}>
												<Tag color="orange" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.energyCategory?.name}</span>
												</Tag>
											</Descriptions.Item>
											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Entity</span>}>
												<Tag color="green" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.entity?.name}</span>
												</Tag>
											</Descriptions.Item>
										</Descriptions>
									</Col>
									<Col span={12}>
										<Descriptions bordered size="small" column={1} style={{ borderRadius: 15, width: '99%', marginLeft: '20px' }}>
											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Indoor/Outdoor</span>}>
												<Tag color="blue" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.indoorOutdoor == null ? ('N/A') : (site_details?.indoorOutdoor?.name)}</span>
												</Tag>
											</Descriptions.Item>
											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Shelter Type</span>}>
												<Tag color="gray" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.shelterType?.name}</span>
												</Tag>
											</Descriptions.Item>
											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Accessible Hours</span>}>
												<Tag color="red" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.weekendsOrHolidaysAccessibleHours}</span>
												</Tag>
											</Descriptions.Item>
											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Outside Ambient Tempture (Cel)</span>}>
												<Tag color="orange" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.ambientTemperature}</span>
												</Tag>
											</Descriptions.Item>
											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Site Key Location</span>}>
												<Tag color="green" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.keyLocation ? site_details?.keyLocation : 'N/A'}</span>
												</Tag>
											</Descriptions.Item>
											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Site Owner Name</span>}>
												<Tag color="blue" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.siteOwnerNameForAccess ? site_details?.siteOwnerNameForAccess : 'N/A'}</span>
												</Tag>
											</Descriptions.Item>
											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Site Owner Address</span>}>
												<Tag color="gray" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.siteOwnerAddressForAccess ? site_details?.siteOwnerAddressForAccess : 'N/A'}</span>
												</Tag>
											</Descriptions.Item>
										</Descriptions>
									</Col>
								</Row>
							</Fragment>
						</Panel>
					</Collapse>
				</div>

				<div style={{ backgroundColor: 'white', padding: 10, marginTop: 10, borderRadius: 10 }}>
					<Collapse id="siteGenaralDetails2" ghost={true}>
						<Panel header={<span className='textStyles-small' style={{fontSize: 13, fontWeight: 'bold'}} >Site Information Section 02</span>} 
						key="1">
							<Fragment>
								<Row>
									<Col span={12}>
										<Descriptions bordered size="small" column={1} style={{ borderRadius: 15, width: '99%' }}>					
											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>PIS vendor</span>}>
												<Tag color="orange" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.pisVendor ? site_details?.pisVendor : 'N/A'}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Tenants</span>}>
												<Tag color="green" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{this.props.detailssharedOutOperatorses_data?.sharedOutOperatorses_data?.map(txt => <span class="badge badge-outline-warning">{txt.tenant + ''}</span>)}</span>
												</Tag>
											</Descriptions.Item>
										
											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>No. of tenants</span>}>
												<Tag color="red" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.tenants}</span>
												</Tag>
											</Descriptions.Item>
											
											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>No. of power shared oot tenants</span>}>
												<Tag color="orange" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{this.props.detailssharedOutOperatorses_data?.sharedOutOperatorses_data?.length}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Rectification Rank</span>}>
												<Tag color="green" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.rectificationRank}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Land Area in perch</span>}>
												<Tag color="red" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.landAreainPerch}</span>
												</Tag>
											</Descriptions.Item>
										</Descriptions>
									</Col>
									<Col span={12}>
										<Descriptions bordered size="small" column={1} style={{ borderRadius: 15, width: '99%', marginLeft: '20px' }}>
											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Horizontal Manual Distance (m)</span>}>
												<Tag color="orange" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.horizontalManualDisctance}</span>
												</Tag>
											</Descriptions.Item>
											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Vertical Manual Disctance (No of Floors)</span>}>
												<Tag color="green" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.verticalManualDisctance}</span>
												</Tag>
											</Descriptions.Item>
											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Boat Manual Distance by Boat (m)</span>}>
												<Tag color="red" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.boatManualdistancebyBoat}</span>
												</Tag>
											</Descriptions.Item>
											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Travel time from depot (Minutes)</span>}>
												<Tag color="orange" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.travelTimeFrom}</span>
												</Tag>
											</Descriptions.Item>
											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Weekends/Holidays Accessible hours</span>}>
												<Tag color="green" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.weekendsOrHolidaysAccessibleHours}</span>
												</Tag>
											</Descriptions.Item>
											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Site On Air Date</span>}>
												<Tag color="red" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.siteOnAirDate}</span>
												</Tag>
											</Descriptions.Item>
										</Descriptions>
									</Col>
								</Row>
							</Fragment>
						</Panel>
					</Collapse>
				</div>

				<div style={{ backgroundColor: 'white', padding: 10, marginTop: 10, borderRadius: 10 }}>
					<Collapse ghost={true}>
						<Panel header={<span className='textStyles-small' style={{ fontSize: 13, fontWeight: 'bold' }} >Power Summery Details</span>}
							key="1">
							<Fragment>
								<Row>
									<Col span={12}>
										<Descriptions bordered size="small" column={1} style={{ borderRadius: 15, width: '99%' }}>
											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Power Connection type</span>}>
												<Tag color="pink" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{this.props.details1.access_technologies_data?.mpcPowerConnectionType != null ? (this.props.details1.access_technologies_data?.mpcPowerConnectionType.name) : ('null')}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>No. of phases</span>}>
												<Tag color="orange" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{this.props.details1?.access_technologies_data?.mpcNoOfPhases != null ? (this.props.details1?.access_technologies_data?.mpcNoOfPhases) : ('null')}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Installed Solar Capacity (kW)</span>}>
												<Tag color="green" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{this.props.details3?.renewableEnergySolars_data?.map(txt => <span class="badge badge-outline-warning">{txt.pvcapacity + ''}</span>)}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>No of Rectifiers</span>}>
												<Tag color="red" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.noofRectifiers != null ? (site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.noofRectifiers) : ('null')}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Rectifier - Make</span>}>
												<Tag color="orange" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{this.props.detailsRectifiersData?.rectifiers_data?.map(txt => <span class="badge badge-outline-warning">{txt.make?.name + ''}</span>)}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>No of Battery Banks</span>}>
												<Tag color="green" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.noofBatteryBanks != null ? (site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.noofBatteryBanks) : ('null')}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>BB Type</span>}>
												<Tag color="red" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{this.props.detailsBatteryBanksData?.battery_banks_data?.map(txt => <span class="badge badge-outline-success">{txt.bbType?.name + ''}</span>)}</span>
												</Tag>
											</Descriptions.Item>
										</Descriptions>
									</Col>

									<Col span={12}>
										<Descriptions bordered size="small" column={1} style={{ borderRadius: 15, width: '99%', marginLeft: '20px' }}>
											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Designed Back up time</span>}>
												<Tag color="orange" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.designedBackUpTime != null ? (site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.designedBackUpTime) : ('null')}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Actual Back up time</span>}>
												<Tag color="green" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.actualBackUpTime != null ? (site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.actualBackUpTime) : ('null')}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>No. of Cabinets</span>}>
												<Tag color="red" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.noofCabinets != null ? (site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.noofCabinets) : ('null')}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>No of Air Conditioners</span>}>
												<Tag color="orange" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.noofAirConditioners != null ? (site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.noofAirConditioners) : ('null')}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>No. of Generators</span>}>
												<Tag color="green" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.noofGenerators != null ? (site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.noofGenerators) : ('null')}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Generator - Capacity(kVA)</span>}>
												<Tag color="red" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{this.props.detailsGeneratorsData?.generators_data?.map(txt => <span class="badge badge-outline-warning">{txt.capacity + ''}</span>)}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Generator - Make</span>}>
												<Tag color="orange" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{this.props.detailsGeneratorsData?.generators_data?.map(txt => <span class="badge badge-outline-warning">{txt.make?.name + ''}</span>)}</span>
												</Tag>
											</Descriptions.Item>

										</Descriptions>
									</Col>
								</Row>
							</Fragment>
						</Panel>
					</Collapse>
				</div>

				<div style={{ backgroundColor: 'white', padding: 10, marginTop: 10, borderRadius: 10 }}>
					<Collapse ghost={true}>
						<Panel header={<span className='textStyles-small' style={{ fontSize: 13, fontWeight: 'bold' }} >Details Of Tower</span>} 
						key="1">
							<Fragment>


										<Row>
											<Col span={12}>
											<Descriptions bordered size="small" column={1} style={{ borderRadius: 15, width: '99%' }}>
											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Tower Height</span>}>
												<Tag color="pink" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details.towerHeight}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Tower Category</span>}>
												<Tag color="orange" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details.towerCategory.name}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Tower Type</span>}>
												<Tag color="green" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details.towerType.name}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Tower Legs Count</span>}>
												<Tag color="red" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details.towerLegsCount != null ? site_details.towerLegsCount : "N/A"}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Manual Distance</span>}>
												<Tag color="orange" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details.manualDistance !== '' ? site_details.manualDistance : "N/A"}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Access Permission Providing by</span>}>
												<Tag color="orange" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details.accessPermissionProvidingParty !== "" ? site_details.accessPermissionProvidingParty : "N/A"}</span>
												</Tag>
											</Descriptions.Item>

											</Descriptions>
											</Col>

											<Col span={12}>
											<Descriptions bordered size="small" column={1} style={{ borderRadius: 15, width: '99%', marginLeft: '20px' }}>


											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Distance From Regional Office</span>}>
												<Tag color="green" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details.distanceFromRegionalOffice != null ? site_details.distanceFromRegionalOffice + " Km" : "N/A"}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Capacity PePhase</span>}>
												<Tag color="red" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details.capacityPePhase !== '' ? site_details.capacityPePhase : "N/A"}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>PG Possibility</span>}>
												<Tag color="orange" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details.pgPossibility != null ? site_details.pgPossibility : "N/A"}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Tower Owner Category</span>}>
												<Tag color="green" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details.towerOwnerCategory !== null ? site_details.towerOwnerCategory : "N/A"}</span>
												</Tag>
											</Descriptions.Item>

											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>PG connecting setup availability</span>}>
												<Tag color="red" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details.portablegenConnectingSetupAvailability ? "Yes" : "No"}</span>
												</Tag>
											</Descriptions.Item>

											</Descriptions>
											</Col>
										</Row>
							</Fragment>
						</Panel>
					</Collapse>
				</div>

				<div style={{ backgroundColor: 'white', padding: 10, marginTop: 10, borderRadius: 10 }}>
					<Collapse ghost={true}>
						<Panel header={<span className='textStyles-small' style={{ fontSize: 13, fontWeight: 'bold' }} >Region Details</span>} key="1">
								<Fragment>
								<Row>
									<Col span={12}>
									<Descriptions
										bordered
										size="small"
										column={1}
										style={{ borderRadius: 15, width: '99%' }}
									>
										<Descriptions.Item
											style={{ backgroundColor: 'white' }}
											label={<span className='textStyles-small' style={{ fontSize: 12 }}>Region Name</span>}
										>
											<Tag color="pink" style={{ borderRadius: 5 }}>
												<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details.depot.region.name}</span>
											</Tag>
										</Descriptions.Item>
									</Descriptions>
									</Col>

									<Col span={12}>
									<Descriptions
										bordered
										size="small"
										column={1}
										style={{ borderRadius: 15, width: '99%', marginLeft: '20px' }}
									>
										<Descriptions.Item
											style={{ backgroundColor: 'white' }}
											label={<span className='textStyles-small' style={{ fontSize: 12 }}>ParentRegion Region</span>}
										>
											<Tag color="green" style={{ borderRadius: 5 }}>
												<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details.depot.region.parentRegion != null ? site_details.depot.region.parentRegion.name : site_details.depot.region.name}</span>
											</Tag>
										</Descriptions.Item>
									</Descriptions>
									</Col>
								</Row>
								</Fragment>
						</Panel>
					</Collapse>
				</div>

				<div style={{ backgroundColor: 'white', padding: 10, marginTop: 10, borderRadius: 10 }}>
					<Collapse ghost={true}>
						<Panel header={<span className='textStyles-small' style={{ fontSize: 13, fontWeight: 'bold' }} >Depot Details</span>} key="1">
							<Fragment>
								<Row>
									<Col span={24}>
										<Descriptions
											bordered
											size="small"
											column={2}
											style={{ borderRadius: 15 }}
										>
											<Descriptions.Item
												style={{ backgroundColor: 'white' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Depot Name</span>}
											>
												<Tag color="pink" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details.depot.name}</span>
												</Tag>
											</Descriptions.Item>
											<Descriptions.Item
												style={{ backgroundColor: '#F3F4F7' }}
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Depot Description</span>}
											>
												<Tag color="orange" style={{ borderRadius: 5 }}>
													<span className="textStyles-small" style={{ fontSize: 10 }}>{site_details.depot.description}</span>
												</Tag>
											</Descriptions.Item>
										</Descriptions>
										<Table
											size='small'
											style={{ marginTop: 5 }}
											fontSize={9}
											columns={columns123}
											className="textStyles-small"
											bordered
											pagination={false}
											loading={this.state.loading}
											dataSource={site_details.depot != null && site_details.depot.depotOfficers}
											title={() => <span className="fw-semibold m-0">Depot Officers:</span>}
										/>
									</Col>
								</Row>
								{/* <div className="card-body pt-3">

							<div className="row">

								<Table
									size='small'
									fontSize={9}
									columns={columns123}
									bordered
									pagination={false}
									loading={this.state.loading}
									dataSource={site_details.depot != null && site_details.depot.depotOfficers}
									title={() => <h6 className="fw-semibold m-0">Depot Officers:</h6>}
								/>
							</div>
						</div>
					</div> */}
							</Fragment>
						</Panel>
					</Collapse>
				</div>

				<div style={{ backgroundColor: 'white', padding: 10, marginTop: 10, borderRadius: 10 }}>
					<Collapse ghost={true}>
						<Panel header={<span className='textStyles-small' style={{ fontSize: 13, fontWeight: 'bold' }} >Other details</span>}
						key="1">
							<Fragment>
								<div className="d-flex justify-content-end align-items-center" style={{marginRight: 10}}>
								  {this.state.switchStatus
									? <button type="button" className="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.dataUpdate()}> <i className="mdi mdi-pencil-outline"></i></button>
									: <div className="d-flex justify-content-end align-items-center">
										<button type="button" className="btn btn-soft-secondary" onClick={() => this.close()} style={{ marginRight: "10px" }}><span className="textStyles-small">Cancel</span></button>
										<button type="button" className="btn btn-soft-primary" onClick={() => this.dataSetToUpdate()}><span className="textStyles-small">Update Data</span></button>
									  </div>
								  }
								</div>
								<ul class="list-group" style={{marginTop: 10}}>
									<Row>
										<Col span={12}>
											<div style={{ width: '99%' }}>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<span className='textStyles-small'>
															No. of  WIP projects
														</span>
													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.puNoOfwipProjects != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.puNoOfwipProjects}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <InputNumber min={0} style={{ width: '20%' }} className='borderedSelect' value={this.state.objectSet?.puNoOfwipProjects} id="puNoOfwipProjects" onChange={this.handlpuNoOfwipProjects} /> </>)}
												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<span className='textStyles-small'>
															Power Dependencies
														</span>
													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.sdPowerDependencies != null ?
														(<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.sdPowerDependencies}</span>) :
														(<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) :
														(<>  <Input className='borderedSelect' style={{ width: '20%' }} value={this.state.objectSet?.sdPowerDependencies} id="sdPowerDependencies" onChange={this.handlsdPowerDependencies} /> </>)}
												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<span className='textStyles-small'>SAQ Dependencies</span>
													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.sdSAQDependencies != null ?
														(<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.sdSAQDependencies}</span>) :
														(<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) :
														(<>  <Input className='borderedSelect' style={{ width: '20%' }} value={this.state.objectSet?.sdSAQDependencies} id="sdSAQDependencies" onChange={this.handlsdSAQDependencies} /> </>)}
												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<span className='textStyles-small'>Civil Dependencies</span>
													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.sdCivilDependencies != null ?
														(<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.sdCivilDependencies}</span>) :
														(<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) :
														(<>  <Input className='borderedSelect' style={{ width: '20%' }} value={this.state.objectSet?.sdCivilDependencies} id="sdCivilDependencies" onChange={this.handlsdCivilDependencies} /> </>)}
												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<span className='textStyles-small'>Last Month Commercial Power  Consumption  (kWh)</span>
													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.lastMonthCommercialPowerConsumption != null ?
														(<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.lastMonthCommercialPowerConsumption}</span>) :
														(<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) :
														(<>  <InputNumber className='borderedSelect' min={0} style={{ width: '20%' }} value={this.state.objectSet?.lastMonthCommercialPowerConsumption} id="lastMonthCommercialPowerConsumption" onChange={this.handllastMonthCommercialPowerConsumption} /> </>)}
												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<span className='textStyles-small'>Last Month Solar Generation  (kWh)</span>
													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.lastMonthSolarGeneration != null ?
														(<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.lastMonthSolarGeneration}</span>) :
														(<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) :
														(<>  <InputNumber className='borderedSelect' min={0} style={{ width: '20%' }} value={this.state.objectSet?.lastMonthSolarGeneration} id="lastMonthSolarGeneration" onChange={this.handllastMonthSolarGeneration} /> </>)}
												</li>
											</div>
										</Col>

										<Col span={12}>
											<div style={{ width: '99%' }}>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<span className='textStyles-small'>Last Month Generator Running Hours (Hr)</span>
													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.lastMonthGeneratorRunningHours != null ?
														(<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.lastMonthGeneratorRunningHours}</span>) :
														(<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>
															<InputNumber className='borderedSelect' min={0} style={{ width: '20%' }} value={this.state.objectSet?.lastMonthGeneratorRunningHours} id="lastMonthGeneratorRunningHours" onChange={this.handllastMonthGeneratorRunningHours} /> </>)}
												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<span className='textStyles-small'>Last Month PG Running Hours (Hr)</span>
													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.lastMonthPGRunningHours != null ?
														(<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.lastMonthPGRunningHours}</span>) :
														(<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>
															<InputNumber className='borderedSelect' min={0} style={{ width: '20%' }} value={this.state.objectSet?.lastMonthPGRunningHours} id="lastMonthPGRunningHours" onChange={this.handllastMonthPGRunningHours} /> </>)}
												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<span className='textStyles-small'>Last Month Commercial Power Cost (LKR)</span>
													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.lastMonthCommercialPowerCost != null ?
														(<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.lastMonthCommercialPowerCost}</span>) :
														(<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>
															<InputNumber className='borderedSelect' min={0} style={{ width: '20%' }} value={this.state.objectSet?.lastMonthCommercialPowerCost} id="lastMonthCommercialPowerCost" onChange={this.handllastMonthCommercialPowerCost} /> </>)}
												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<span className='textStyles-small'>Last Month Fuel Filling  Liters</span>
													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.lastMonthFuelFillingLiters != null ?
														(<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.lastMonthFuelFillingLiters}</span>) :
														(<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>
															<InputNumber className='borderedSelect' min={0} style={{ width: '20%' }} value={this.state.objectSet?.lastMonthFuelFillingLiters} id="lastMonthFuelFillingLiters" onChange={this.handllastMonthFuelFillingLiters} /> </>)}
												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<span className='textStyles-small'>Last Month Fuel Cost (LKR)</span>
													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.lastMonthFuelCost != null ?
														(<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.lastMonthFuelCost}</span>) :
														(<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>
															<InputNumber className='borderedSelect' min={0} style={{ width: '20%' }} value={this.state.objectSet?.lastMonthFuelCost} id="airCoolingMethod" onChange={this.handllastMonthFuelCost} /> </>)}
												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<span className='textStyles-small'>Last Month PG Cost (LKR)</span>
													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.lastMonthPGCost != null ?
														(<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.lastMonthPGCost}</span>) :
														(<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>
															<InputNumber className='borderedSelect' min={0} style={{ width: '20%' }} value={this.state.objectSet?.lastMonthPGCost} id="airCoolingMethod" onChange={this.handllastMonthPGCost} /> </>)}
												</li>
											</div>
										</Col>
									</Row>
								</ul>
							</Fragment>
						</Panel>
					</Collapse>
				</div>
			</div>
		);
	}
}

export default GeneralDetails;
